import 'whatwg-fetch';
import config, {AUTH_INVALID_MESSAGE, AUTH_INVALID_TOKEN, SERVICE_LOGICAL_ACCESS_DENIED} from '../../config/config';
import {dropToken} from "../../components/UserLogin/actions";
import {toastr} from "react-redux-toastr";

export const SEARCH_PAGE_COUNT = 20;
export const REQUEST_SEARCH = 'REQUEST_SEARCH';
export const RECEIVE_SEARCH = 'RECEIVE_SEARCH';
export const RECEIVE_SEARCH_UPDATE = 'RECEIVE_SEARCH_UPDATE';
export const SET_SEARCH_CONTEXT = 'SET_SEARCH_CONTEXT';

export const SEARCH_CONTEXT_PRODUCTS = 'products';
export const SEARCH_CONTEXT_ANIMAL_HUSBANDRY = 'Животноводство';
export const SEARCH_CONTEXT_CROP_PRODUCTION = 'Растениеводство';
export const SEARCH_CONTEXT_TECHNIQUE = 'Техника';
export const SEARCH_CONTEXT_MANUFACTURING = 'Продукты производства';
export const SEARCH_CONTEXT_ANOTHER_PRODUCTS = 'Услуги';

export const SEARCH_CONTEXT_UNSET = '/';
export const SEARCH_CONTEXT_DEFAULT = config.DEFAULT_SEARCH_IN;

export const SEARCH_CONTEXTS = [
    { key: SEARCH_CONTEXT_PRODUCTS, text: 'Все продукты', value: SEARCH_CONTEXT_PRODUCTS, title: 'в продуктах' },
    { key: SEARCH_CONTEXT_CROP_PRODUCTION, text: 'Растениеводство', value: SEARCH_CONTEXT_CROP_PRODUCTION, title: 'в категории "растениеводство"' },
    { key: SEARCH_CONTEXT_ANIMAL_HUSBANDRY, text: 'Животноводство', value: SEARCH_CONTEXT_ANIMAL_HUSBANDRY, title: 'в категории "животноводство"'},
    { key: SEARCH_CONTEXT_TECHNIQUE, text: 'Техника', value: SEARCH_CONTEXT_TECHNIQUE, title: 'в категории "техника"'},
    { key: SEARCH_CONTEXT_ANOTHER_PRODUCTS, text: 'Услуги', value: SEARCH_CONTEXT_ANOTHER_PRODUCTS, title: 'в категории "услуги"'},
    { key: SEARCH_CONTEXT_MANUFACTURING, text: 'Продукты производства', value: SEARCH_CONTEXT_MANUFACTURING, title: 'в категории "продукты производства"'},

]

export const setSearchContext = (value) => {
    return {
    type: SET_SEARCH_CONTEXT,
    value,
}
};
export const receiveSearch = (context, page, items) => ({
    type: RECEIVE_SEARCH,
    items,
    page,
    context,
});

export const receiveSearchUpdate = (context, page, items) => ({
    type: RECEIVE_SEARCH_UPDATE,
    items,
    page,
    context,
});

export const requestSearch = (context, page) => ({
    type: REQUEST_SEARCH,
    page,
    context,
});

const process_api_error = (json, dispatch) => {
  if (json.code === AUTH_INVALID_TOKEN) {
        dispatch(dropToken());
        toastr.error(AUTH_INVALID_MESSAGE);
  } else
      if (json.code === SERVICE_LOGICAL_ACCESS_DENIED)
        toastr.error(json.message);
      else
        toastr.error('Ошибка сервера: ' + String(json.code) + '. ' + json.message);
};

export const fetchSearch = (token, context, params = {}) => (dispatch) => {

  dispatch(requestSearch(context, params.page));

  let base_url, headers = {};
  switch (context) {
      case SEARCH_CONTEXT_PRODUCTS:
          base_url = config.API_PRODUCTS_URL;
          break;
      default:
          base_url = config.API_PRODUCTS_URL;
          break;
  }

  if (!_.isEmpty(headers) && !token) {
      toastr.error(AUTH_INVALID_MESSAGE);
      dispatch(receiveSearch(context, params.page, []));
      return;
  }

  const url = base_url
        + '?' + Object.keys(params).map((k) => k + '=' + encodeURIComponent(params[k])).join('&')
        + '&version=' + config.APP_VERSION;

  return fetch(url, {headers})
    .then((response) => response.json())
      .then((json) => {
        if (json.code ?? 200 === 200) {
          dispatch(receiveSearch(context, params.page, json.data ?? json));
        } else {
          process_api_error(json, dispatch);
        }
      })
      .catch( (ex) => toastr.error('Ошибка запроса: ' + String(ex)));
};
