import { Link } from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {Card, Button, Image, Modal, Input} from 'semantic-ui-react';
import heartIcon from '../../icons/product-card-heart-icon.png';
import filledHeartIcon from '../../icons/favorite-icon.png';
import './style.css';
import {useDispatch, useSelector} from 'react-redux';
import { toastr } from 'react-redux-toastr';

import {getCountries, isCountriesFetching} from "../AddressCard/reducer";
import {getAuthToken} from "../UserLogin/reducer";
import {switchFavoriteCard} from "../../views/Favorite/actions"
import {getFavoriteCards} from "../../views/Favorite/reducer";
import _ from "lodash";
import {putTicket} from "../UserLogin/actions";
import PhoneCountryMask from "../AddressCard/PhoneCountryMask";
import {ADDRESS_PHONE, ADDRESS_PHONE_COUNTRY} from "../AddressCard/actions";
import {ReactComponent as CloseIcon} from "../../icons/filter-close-button.svg";
import {isMobile} from "react-device-detect";

export default function ProductCard(props) {
    const {
        categories: propCat,
        id,
        src,
        name,
        price,
        seller_company,
        seller_company_id,
        seller_company_logo
    } = props;
    const categories = propCat.map((category) => category.name);
    const dispatch = useDispatch();
    const [showAllCategories, setShowAllCategories] = useState(false);
    const favoriteCards = useSelector((state) => getFavoriteCards(state.favorites));
    const isFavorite = favoriteCards.some((card) => card.id === id);
    const [open, setOpen] = useState(false);

    const handleClickBookmark = () => {
        dispatch(switchFavoriteCard({
            categories: propCat,
            id,
            src,
            name,
            seller_company,
            seller_company_id
        }))

        if (isFavorite) {
            toastr.warning('Товар' + ' ' + name + ' ' + 'удален из избранного')
        } else {
            toastr.success('Товар' + ' ' + name + ' ' + 'добавлен в избранное')

        }
    }

    const ModalRequestProduct = (props) => {
        const countries = useSelector((state) => getCountries(state.address));
        const isFetching = useSelector((state) => isCountriesFetching(state.address));
        const [mail, setMail] = useState("");
        const [mailDirty, setMailDirty] = useState(false);
        const [mailError, setMailError] = useState("Поле ввода не может быть пустым");


        const [phoneNumber, setPhoneNumber] = useState("");
        const [userName, setUserName] = useState('');
        const [userEmail, setUserEmail] = useState('');
        const [formValid, setFormValid] = useState(false);
        const [messageValue, setMessageValue] = useState('');

        const dispatch = useDispatch();
        const token = useSelector((state) => getAuthToken(state.userLogin));


        let listPhoneCountries = [];
        if (!isFetching && countries && countries.countries) {
            listPhoneCountries = Object.values(countries.countries).map((element, index) => (
                {
                    key: index,
                    text: element.country_ru,
                    flag: _.lowerCase(element.iso),
                    value: element.iso,
                    className: "dropdown-item",
                    phone_mask: element.phone_mask,
                }
            ));
        }

        const handleUserNameChange = (e) => {
            setUserName(e.target.value);
        }


        useEffect(() => {
            if (mailError) {
                setFormValid(false)
            } else {
                setFormValid(true)
            }
        }, [mailError]);

        const mailHandler = (e) => {
            setMail(e.target.value);
            const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (!re.test(String(e.target.value).toLowerCase())) {
                setMailError("Некорректный email адрес")
            } else {
                setMailError("")
            }
        }

        const nameHandler = (e) => {
            setUserName(e.target.value);
        }

        const textHandler = (e) => {
            setMessageValue(e.target.value);
        }

        const blurHandler = (e) => {
            switch (e.target.name) {
                case 'mail':
                    setMailDirty(true);
                    break;
            }
        }

        const handleChangeField = (fieldName, value) => {
            setPhoneNumber(value);
        }

        const createEmailTicket = (to, subject, message, email, phone, who) => {
            const ticket = {
                subject,
                message,
                email,
                phone,
                who,
                to,
            };
            dispatch(putTicket(token, ticket))
            // .then(() => {
            //     setIsLoading(false);
            // })
            // .catch(() => {
            //     setIsLoading(false);
            // });

        }

        const handleSubmitQuestion = () => {
            if (messageValue && phoneNumber && userName && mail) {
                createEmailTicket(
                    'egor-ka.kravchenko@yandex.ru',
                    'Коммерческое предложение на товар ' + ' ' + name + ' ' + ' через VENUE',
                    name + ', ' + messageValue,
                    mail,
                    phoneNumber,
                    userName
                )
                toastr.success(`Запрос успешно отправлен!`)
                setOpen(false)
                setMessageValue('')
                setMail('')
                setPhoneNumber('')
            }

            if (!phoneNumber || !userName || !mail || !messageValue) {
                toastr.warning(`Пожалуйста, заполните все поля`)
            }

        }

        const handleClose = () => {
            setOpen(false)
            setMailError('')
            setMailDirty(false)
        }


        return (
            <Modal
                open={open}
                onClose={() => handleClose()}
                size='tiny'
                className='modal-request'
                centered={false}
            >

                <CloseIcon className="modal-request-close-icon" onClick={() => setOpen(false)}/>
                <Modal.Content className="modal-request-content">
                    <div className="modal-request-title">Получите коммерческое предложение по данному товару</div>
                    <Input
                        className='modal-request-placeholder'
                        type='text'
                        name='name'
                        placeholder='ФИО'
                        transparent
                        onChange={e => {
                            nameHandler(e);
                            handleUserNameChange(e)
                        }}
                        onBlur={e => blurHandler(e)}
                        value={userName}
                    />

                    <PhoneCountryMask
                        extraClass="edit-input-phone edit-input"
                        name="phone"
                        countries={listPhoneCountries}
                        country="RU"
                        loading={isFetching}
                        phone={phoneNumber}
                        handleChangeField={handleChangeField}
                        nameCountry={ADDRESS_PHONE_COUNTRY}
                        namePhone={ADDRESS_PHONE}
                        fluid={true}
                    />
                    <Input
                        className='modal-request-placeholder'
                        type='text'
                        name='mail'
                        placeholder='Электронная почта'
                        transparent
                        value={mail}
                        onChange={e => mailHandler(e)}
                        onBlur={e => blurHandler(e)}
                    />
                    {(mailDirty && mailError) && <div style={{color: 'red'}}>{mailError}</div>}
                    <Input
                        className='modal-request-placeholder'
                        type='text'
                        name='question'
                        placeholder='Примерный вес продукции (40кг, 700кг, 1т)'
                        transparent
                        value={messageValue}
                        onChange={e => textHandler(e)}
                        onBlur={e => blurHandler(e)}
                    />

                </Modal.Content>
                <Button className='modal-request-btn' onClick={handleSubmitQuestion}>
                    Запросить
                </Button>
            </Modal>
        );
    };

    const categoryLinks = {
        193: '/catalog/animal-husbandry',
        197: '/catalog/plant-production',
        198: '/technique',
        199: '/catalog/manufacturing-products',
        223: '/services'
    };

    return (
        <div className="div-product-card">
            <Card raised className="product-card">
                <Card.Content className="product-card-content">
                    <Link to={'/products/product-card/' + id}>
                        <Image
                            className="product-img"
                            src={src}
                            wrapped
                            size="small"
                            style={{ marginLeft: '55px', marginBottom: '7px', marginTop: '8px' }}
                        />
                    </Link>
                    <Link onClick={() => handleClickBookmark()} style={{position: 'absolute', top: '10px', right: '10px'}}>
                        {isFavorite ? <img src={filledHeartIcon} alt='' className='heart-icon'/> : <img src={heartIcon} alt="" className="heart-icon"/>}
                    </Link>
                    <Card.Header className="card-header">{name}</Card.Header>
                    <Card.Description>
                        <div className="card-text">
                            <span>Производители: </span>
                            <Link
                                to={'/congress-online/company/' + seller_company_id}
                                className="categories-product-card"
                                dangerouslySetInnerHTML={{ __html: seller_company }}
                            />
                            <br />
                            <span>Метки: </span>
                            {propCat.slice(0, showAllCategories ? propCat.length : 4).map((category, index) => (
                                category.id !== 196 && (
                                    <React.Fragment key={category.id}>
                                        <Link
                                            to={categoryLinks[category.id] || `/search/${category.name}`} // Переход по ссылке из categoryLinks, если id есть, иначе обычная ссылка
                                            className="categories-product-card"
                                        >
                                            {category.name}
                                        </Link>
                                        {index < propCat.length - 1 && ', '}
                                    </React.Fragment>
                                )
                            ))}
                            {!showAllCategories && propCat.length > 4 && (
                                <span>
                                ...{' '}
                                    <Link
                                        to="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowAllCategories(true);
                                        }}
                                        className="categories-product-card"
                                    >
                                    ещё
                                </Link>
                            </span>
                            )}
                        </div>
                    </Card.Description>
                </Card.Content>
                <Button fluid className="request-button" onClick={() => setOpen(true)}>
                    <p className="request-button-text">Запросить</p>
                </Button>
                <ModalRequestProduct/>
            </Card>
        </div>
    );

}