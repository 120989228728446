import React, {useEffect} from 'react';
import '../Catalog/style.css'
import GradientBox from "../../components/GradientBox/gradientBox";
import { isMobile, isBrowser } from 'react-device-detect';
import FilterModal from "../../components/Filtration/filter";
import FilterDropdownMenu from "../../components/Filtration/dropdownMenu";
import { useSelector} from "react-redux";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import {getProducts, getProductsFilter} from "../Products/reducer";
import {getCategories} from "../Categories/reducer";
import {
    clearProducts,
    fetchProducts,
    setCategoryFilter,
    setDisplayOrderFilter,
    setSellerFilter,
    setTagFilter
} from "../Products/actions";
import ProductLoader from "../../components/ProductLoader";

const Services = (props) => {

    const {dispatch, hasMore, routeAction} = props

    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const filter = useSelector((state) => getProductsFilter(state.products));

    useEffect(() => {
        dispatch(clearProducts())
        if (filter && routeAction === 'PUSH') {
            dispatch(setTagFilter([]));
            dispatch(setCategoryFilter([]))
            dispatch(setDisplayOrderFilter([]))
            dispatch(setSellerFilter([]))
        }
    }, []);

    useEffect(() => {
        readProducts()
        window.scrollTo(0, 0);
    },[])


    const products = useSelector((state) => getProducts(state.products));

    const categories = useSelector(state => getCategories(state.categories));
    const anotherProducts = categories.find(cat => cat.cat_ID === 223)?.category_count || 0;

    const readProducts = () => {
        const allProductIds = categories.reduce((acc, category) => {
            if (category.products_ids) {
                return acc.concat(category.products_ids);
            }
            return acc;
        }, []);

        // console.log(allProductIds);

        allProductIds.forEach(id => {
            dispatch(fetchProducts({
                id: id
            }));
        });
    }


    const mobileView = (
        <div className="service-div">
            <h1 className={'title-text-mobile'} style={{fontSize: '20px', marginTop: '10px', height:'25px'}}>{t('services')}</h1>
            <br/>
            <div style={{position: 'absolute', top: 0, left: '2.4%', paddingRight: '100px'}}>
                <FilterModal isMobile={true} isCompany={true}/>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', marginLeft:'5px'}}>
                <ProductLoader
                    category={Number(223)}
                    loaderText="Загружаем продукты ..."
                    zeroText="Продукты отсутствуют"
                    productsCount={anotherProducts + 1}
                />
            </div>
            <div>
                <GradientBox/>
            </div>
        </div>
    )

    const browserView = (
        <div className="catalog">
            <h1 className={'title-text'} id={'title'}>{t('services')}</h1>
            <FilterDropdownMenu isCompany={true}/>
            <ProductLoader
                category={Number(223)}
                loaderText="Загружаем продукты ..."
                zeroText="Продукты отсутствуют"
                productsCount={anotherProducts + 1}
            />
            <GradientBox/>
        </div>
    )

    return (
        <>
            {isMobile ? mobileView : isBrowser ? browserView : null}
        </>
    )
}

export default Services;