import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../../icons/arrow-icon.svg";
import {useDispatch, useSelector} from "react-redux";
import { getProducts, getProductsFetching } from "../Products/reducer";
import { isMobile, isBrowser } from 'react-device-detect';
import './style.css';
import translations from "../../components/LanguageModal/translations";
import { getLanguage } from "../../components/LanguageModal/reducer";
import ProductsContainer from "../../components/ProductsContainer";
import {fetchCatalogue} from "../Categories/actions";
import {getCategories} from "../Categories/reducer";
import {fetchProducts} from "../Products/actions";

export default function Catalog(props) {

    const dispatch = useDispatch();
    const products = useSelector((state) => getProducts(state.products));
    const loading = useSelector((state) => getProductsFetching(state.products));
    const language = useSelector(getLanguage);
    const categories = useSelector(state => getCategories(state.categories));

    useEffect(() => {
        window.scrollTo(0, 0);
        readCatalogue()
    }, []);

    const readCatalogue = () => {
        dispatch(fetchCatalogue(196, { props }));

        const allProductIds = categories.reduce((acc, category) => {
            if (category.products_ids) {
                return acc.concat(category.products_ids);
            }
            return acc;
        }, []);

        // console.log(allProductIds);

        allProductIds.forEach(id => {
            dispatch(fetchProducts({
                page: 1,
                id: id
            }));
        });
    };


    const t = (key) => {
        const languageString = language[0] + language[1];
        const translation = translations[languageString];
        return translation ? translation[key] : key;
    };

    // console.log(categories)
    // console.log(products)

    const animalHusbandryProducts = categories.find(cat => cat.cat_ID === 193)?.category_count || 0;
    const cropProductionProducts = categories.find(cat => cat.cat_ID === 197)?.category_count || 0;
    const techniqueProducts = categories.find(cat => cat.cat_ID === 198)?.category_count || 0;
    const manufacturingProducts = categories.find(cat => cat.cat_ID === 199)?.category_count || 0;
    const anotherProducts = categories.find(cat => cat.cat_ID === 223)?.category_count || 0;

    const wordsArray = [
        "товар",
        "товара",
        "товаров"
    ];

    function NormalizeCountForm(number, words_arr) {
        number = Math.abs(number);
        if (Number.isInteger(number)) {
            let options = [2, 0, 1, 1, 1, 2];
            return words_arr[(number % 100 > 4 && number % 100 < 20) ? 2 : options[(number % 10 < 5) ? number % 10 : 5]];
        }
        return words_arr[1]
    }

    const MAX_PRODUCTS_TO_DISPLAY = 8;
    const animalHusbandryCategoryId = 193;
    const cropProductionCategoryId = 197;
    const techniqueProductsId = 198;
    const manufacturingProductsId = 199;
    const anotherProductsId = 223;


    const mobileView = (
        <div id="catalog-mobile">
            <div className={'title-text-mobile'}  style={{ fontSize: '20px' }}>{t('catalog')}</div>
            <div className={'catalog-section'}>
                <Link className={'title-text-mobile'} to={"/catalog/animal-husbandry"} style={{ fontSize: '20px', marginBottom: '5px',marginTop:'5px', height:'25px' }}>
                    {t('animalHusbandry')} <ArrowIcon style={{ width: '17px' }} />
                </Link>
                <h2 className={'main-text'} id={'amount-tovar'} style={{ fontSize: '13px', marginTop: '22px' }}>
                    {animalHusbandryProducts} {NormalizeCountForm(animalHusbandryProducts, wordsArray)}
                </h2>
            </div>
            <h3 className={'main-text'} style={{ fontSize: '10px', marginTop: '0px', marginBottom: '7px' }}>{t('browseOffers')}</h3>
            <div className="catalog-filter-container">
            </div>
            <ProductsContainer
                products={products}
                category={animalHusbandryCategoryId}
                maxItems={MAX_PRODUCTS_TO_DISPLAY}
                isLoading={loading}
                loaderText="Загружаем продукты ..."
                zeroText="Прогружаем продукты ..."
            />
            <div className={'catalog-section'}>
                <h1 style={{ marginBottom: 0 }}>
                    <Link className={'title-text-mobile'} to={"/catalog/plant-production"} style={{ fontSize: '20px', marginBottom: '5px',marginTop:'5px', height:'25px' }}>
                        {t('cropProduction')} <ArrowIcon style={{ width: '17px' }} />
                    </Link>
                </h1>
                <h2 className={'main-text'} id={'amount-tovar'} style={{ fontSize: '13px', marginTop: '22px' }}>
                    {cropProductionProducts} {NormalizeCountForm(cropProductionProducts, wordsArray)}
                </h2>
            </div>
            <h3 className={'main-text'} style={{ fontSize: '10px', marginTop: '0px', marginBottom: '7px' }}>{t('browseOffers')}</h3>
            <ProductsContainer
                products={products}
                category={cropProductionCategoryId}
                maxItems={MAX_PRODUCTS_TO_DISPLAY}
                isLoading={loading}
                loaderText="Загружаем продукты ..."
                zeroText="Прогружаем продукты ..."
            />
            <div className={'catalog-section'}>
                <h1 style={{ marginBottom: 0 }}>
                    <Link className={'title-text-mobile'} to={"/technique"} style={{ fontSize: '20px', marginBottom: '5px',marginTop:'5px', height:'25px' }}>
                        {t('technique')} <ArrowIcon style={{ width: '17px' }} />
                    </Link>
                </h1>
                <h2 className={'main-text'} id={'amount-tovar'} style={{ fontSize: '13px', marginTop: '22px' }}>
                    {techniqueProducts} {NormalizeCountForm(cropProductionProducts, wordsArray)}
                </h2>
            </div>
            <h3 className={'main-text'} style={{ fontSize: '10px', marginTop: '0px', marginBottom: '7px' }}>{t('browseOffers')}</h3>
            <ProductsContainer
                products={products}
                category={techniqueProductsId}
                maxItems={MAX_PRODUCTS_TO_DISPLAY}
                isLoading={loading}
                loaderText="Загружаем продукты ..."
                zeroText="Прогружаем продукты ..."
            />
            {/*<div className={'catalog-section'}>*/}
            {/*    <h1 style={{ marginBottom: 0 }}>*/}
            {/*        <Link className={'title-text-mobile'} to={"/services"} style={{ fontSize: '20px', marginBottom: '5px',marginTop:'5px', height:'25px' }}>*/}
            {/*            {t('services')} <ArrowIcon style={{ width: '17px' }} />*/}
            {/*        </Link>*/}
            {/*    </h1>*/}
            {/*    <h2 className={'main-text'} id={'amount-tovar'} style={{ fontSize: '13px', marginTop: '22px' }}>*/}
            {/*        {anotherProducts} {NormalizeCountForm(anotherProducts, wordsArray)}*/}
            {/*    </h2>*/}
            {/*</div>*/}
            {/*<h3 className={'main-text'} style={{ fontSize: '10px', marginTop: '0px', marginBottom: '7px' }}>{t('browseOffers')}</h3>*/}
            {/*<ProductsContainer*/}
            {/*    products={products}*/}
            {/*    category={cropProductionCategoryId}*/}
            {/*    maxItems={MAX_PRODUCTS_TO_DISPLAY}*/}
            {/*    isLoading={loading}*/}
            {/*    loaderText="Загружаем продукты ..."*/}
            {/*    zeroText="Прогружаем продукты ..."*/}
            {/*/>*/}
            <div className={'catalog-section'}>
                <h1 style={{ marginBottom: 0 }}>
                    <Link className={'title-text-mobile'} to={"/catalog/manufacturing-products"} style={{ fontSize: '20px', marginBottom: '5px',marginTop:'5px', justifyContent:'left', textAlign:'left',height:'45px',lineHeight:1}}>
                        Продукты <br/> производства <ArrowIcon style={{ width: '17px' }} />
                    </Link>
                </h1>
                <h2 className={'main-text'} id={'amount-tovar'} style={{ fontSize: '13px', marginTop: '22px' }}>
                    {manufacturingProducts} {NormalizeCountForm(manufacturingProducts, wordsArray)}
                </h2>
            </div>
            <h3 className={'main-text'} style={{ fontSize: '10px', marginTop: '0px', marginBottom: '7px' }}>{t('browseOffers')}</h3>
            <ProductsContainer
                products={products}
                category={manufacturingProductsId}
                maxItems={MAX_PRODUCTS_TO_DISPLAY}
                isLoading={loading}
                loaderText="Загружаем продукты ..."
                zeroText="Прогружаем продукты ..."
            />
        </div>
    );

    const browserView = (
        <div className="catalog">
            <h1 className={'title-text'} id={'title'}>{t('catalog')}</h1>
            <div className={'catalog-section'}>
                <h1 style={{ marginBottom: 0 }}>
                    <Link className={'title-text'} to={"/catalog/animal-husbandry"}>
                        {t('animalHusbandry')} <ArrowIcon style={{ marginBottom: '6px' }} />
                    </Link>
                </h1>
                <h2 className={'main-text'} id={'amount-tovar'}>
                    {animalHusbandryProducts} {NormalizeCountForm(animalHusbandryProducts, wordsArray)}
                </h2>
            </div>
            <h3 className={'main-text'} style={{marginTop:'10px', marginBottom:'10px'}}>{t('browseOffers')}</h3>
            <ProductsContainer
                products={products}
                category={animalHusbandryCategoryId}
                maxItems={MAX_PRODUCTS_TO_DISPLAY}
                isLoading={loading}
                loaderText="Загружаем продукты ..."
                zeroText="Прогружаем продукты ..."
            />
            <div className={'catalog-section'}>
                <h1 style={{ marginBottom: 0 }}>
                    <Link className={'title-text'} to={"/catalog/plant-production"}>
                        {t('cropProduction')} <ArrowIcon style={{ marginBottom: '6px'}} />
                    </Link>
                </h1>
                <h2 className={'main-text'} id={'amount-tovar'}>
                    {cropProductionProducts} {NormalizeCountForm(cropProductionProducts, wordsArray)}
                </h2>
            </div>
            <h3 className={'main-text'} style={{marginTop:'10px', marginBottom:'10px'}}>{t('browseOffers')}</h3>
            <ProductsContainer
                products={products}
                category={cropProductionCategoryId}
                maxItems={MAX_PRODUCTS_TO_DISPLAY}
                isLoading={loading}
                loaderText="Загружаем продукты ..."
                zeroText="Прогружаем продукты ..."
            />
            <div className={'catalog-section'}>
                <h1 style={{ marginBottom: 0 }}>
                    <Link className={'title-text'} to={"/technique"}>
                        {t('technique')} <ArrowIcon style={{ marginBottom: '6px'}} />
                    </Link>
                </h1>
                <h2 className={'main-text'} id={'amount-tovar'}>
                    {techniqueProducts} {NormalizeCountForm(cropProductionProducts, wordsArray)}
                </h2>
            </div>
            <h3 className={'main-text'} style={{marginTop:'10px', marginBottom:'10px'}}>{t('browseOffers')}</h3>
            <ProductsContainer
                products={products}
                category={techniqueProductsId}
                maxItems={MAX_PRODUCTS_TO_DISPLAY}
                isLoading={loading}
                loaderText="Загружаем продукты ..."
                zeroText="Прогружаем продукты ..."
            />
            {/*<div className={'catalog-section'}>*/}
            {/*    <h1 style={{ marginBottom: 0 }}>*/}
            {/*        <Link className={'title-text'} to={"/services"}>*/}
            {/*            {t('services')} <ArrowIcon style={{ marginBottom: '6px'}} />*/}
            {/*        </Link>*/}
            {/*    </h1>*/}
            {/*    <h2 className={'main-text'} id={'amount-tovar'}>*/}
            {/*        {anotherProducts} {NormalizeCountForm(anotherProducts, wordsArray)}*/}
            {/*    </h2>*/}
            {/*</div>*/}
            {/*<h3 className={'main-text'} style={{marginTop:'10px', marginBottom:'10px'}}>{t('browseOffers')}</h3>*/}
            {/*<ProductsContainer*/}
            {/*    products={products}*/}
            {/*    category={anotherProductsId}*/}
            {/*    maxItems={MAX_PRODUCTS_TO_DISPLAY}*/}
            {/*    isLoading={loading}*/}
            {/*    loaderText="Загружаем продукты ..."*/}
            {/*    zeroText="Прогружаем продукты ..."*/}
            {/*/>*/}
            <div className={'catalog-section'}>
                <h1 style={{ marginBottom: 0 }}>
                    <Link className={'title-text'} to={"/catalog/manufacturing-products"}>
                        Продукты производства <ArrowIcon style={{ marginBottom: '6px'}} />
                    </Link>
                </h1>
                <h2 className={'main-text'} id={'amount-tovar'}>
                    {manufacturingProducts} {NormalizeCountForm(manufacturingProducts, wordsArray)}
                </h2>
            </div>
            <h3 className={'main-text'} style={{marginTop:'10px', marginBottom:'10px'}}>{t('browseOffers')}</h3>
            <ProductsContainer
                products={products}
                category={manufacturingProductsId}
                maxItems={MAX_PRODUCTS_TO_DISPLAY}
                isLoading={loading}
                loaderText="Загружаем продукты ..."
                zeroText="Прогружаем продукты ..."
            />
        </div>
    );

    return (
        <div className="catalog">
            {isMobile ? mobileView : isBrowser ? browserView : null}
        </div>
    );
}
