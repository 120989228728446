import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Container, Header, Loader } from 'semantic-ui-react';
import ProductCard from '../ProductCard/ProductCard';
import './style.css';
import { getProductsFetching } from "../../views/Products/reducer";
import {fetchCatalogue} from "../../views/Categories/actions";

const ProductsContainer = (props) => {
    const {
        category,
        maxItems,
        loaderText,
        zeroText,
        headerTitle,
        nextFetch,
        hasMore
    } = props;

    const dispatch = useDispatch();

    const products = useSelector(state => state.products.items);
    const fetching = useSelector(state => getProductsFetching(state.products));
    const categories = useSelector(state => state.categories.items);

    const filteredProducts = products
        .filter(product => product.categories.some(cat => cat.id === category))
        .slice(0, maxItems);

    return (
        <>
            <Loader active={fetching}>{loaderText}</Loader>
            {headerTitle && (
                <Header className="limited-view-header" textAlign="center">
                    {headerTitle}
                </Header>
            )}
            {!fetching && filteredProducts.length === 0 ? (
                <Container>
                    <p>{zeroText}</p>
                </Container>
            ) : (
                <div className="products-grid">
                    {filteredProducts.map(product => (
                        <div key={product.id}>
                            <ProductCard
                                id={product.id}
                                src={product.images[0].src}
                                name={product.name}
                                categories={product.categories}
                                shortDescription={product.short_description}
                                categoryId={product.categories[0].id}
                                has_options={product.has_options}
                                virtual={product.virtual}
                                seller_company={product.seller_company.name}
                                seller_company_id={product.seller_company.id}
                                seller_company_logo={product.seller_company.logo}
                            />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default ProductsContainer;
