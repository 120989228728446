import React, { Component } from 'react';
import { Header } from 'semantic-ui-react';
import ProductCard from './ProductCard/ProductCard';

class ProductsList extends Component {
  render() {
    const { products, title } = this.props;
    const list = products.map((element, index) => (
      <ProductCard
        key={element.id}
        id={element.id}
        src={element.images[0].src}
        name={element.name}
        categories={element.categories}
        shortDescription={element.short_description}
        categoryId={element.categories[0].id}
        has_options={element.has_options}
        virtual={element.virtual}
        seller_company={element.seller_company.name}
        seller_company_id={element.seller_company.id}
        seller_company_logo={element.seller_company.logo}
        {...this.props}
      />
    ));
    // console.log(list);
    return (
      <div>
        <Header textAlign="center">{title}</Header>
        <div className="products-grid">{list}</div>
      </div>
    );
  }
}

export default ProductsList;
