import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { fetchProducts, PRODUCTS_PAGE_COUNT } from '../Products/actions';
import { getProductsFetching, getProducts, getProductsHasMore } from '../Products/reducer';
import ProductsList from '../../components/ProductsList';
import { useParams } from 'react-router';


import { getCategories } from "../Categories/reducer";
import InfiniteView from "../../components/InfiniteView";
import { setWindowScrollPosition } from "../../components/WindowDimensions";
import config from "../../config/config";
import ProductsContainer from "../../components/ProductsContainer";
import {CATEGORIES_PAGE_COUNT, fetchCategories} from "../Categories/actions";

export default function Tags(props) {

    const {dispatch, userData, userLocation, mixpanel, routeAction, isPageReloaded} = props;

    const {categId} = useParams();

    const loading = useSelector((state) => getProductsFetching(state.products));
    const products = useSelector((state) => getProducts(state.products));
    const categories = useSelector((state) => getCategories(state.categories));
    const hasMore = useSelector((state) => getProductsHasMore(state.products));
    const currentCategory = categories.find((category) => Number(category.id) === Number(categId));
    const currentCategoryTitle = currentCategory ? currentCategory.name : '';

    useEffect(() => {
        if ( routeAction === 'PUSH' || isPageReloaded ) {
            readProducts(1);
            readCategories(categId, 1)
            setWindowScrollPosition();
        }
        if (mixpanel)
            mixpanel.track('Products List View', {...userLocation, ...userData, categId});
    }, [categId]);

    useEffect(() => {
        if (isPageReloaded) {
            setWindowScrollPosition();
        }
    }, [props]);

    function  loadProducts() {
        const nextPage = Math.round(products.length / PRODUCTS_PAGE_COUNT) + 1;
        readProducts(nextPage);
    }

    const readCategories = (parent, page) => {
        dispatch(fetchCategories(parent, {
            page,
            parent,
            per_page: 50,
            orderby: 'name',
            order: 'asc',
        }));
    };

    function readProducts(page) {
        dispatch(fetchProducts({
            page,
            order: 'asc',
            orderby: 'title',
            home_category: config.CATALOG_CATEGORIES_ID,
        }));
    }

    return (
        <>
            <span className='card-product title'>{currentCategoryTitle}</span>
            <br/>
            <ProductsContainer
                products={products}
                category={Number(categId)}
                isLoading={loading}
                loaderText="Загружаем продукты ..."
                zeroText="Продукты данной категории отсутствуют"
            />
        </>

    );
}

