import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import {Loader, Container, Header, Dimmer} from 'semantic-ui-react';

import {
    fetchSearch,
    receiveSearch, SEARCH_CONTEXT_ANIMAL_HUSBANDRY, SEARCH_CONTEXT_ANOTHER_PRODUCTS, SEARCH_CONTEXT_CROP_PRODUCTION,
    SEARCH_CONTEXT_PRODUCTS,
    SEARCH_CONTEXTS,
    SEARCH_PAGE_COUNT
} from './actions';
import {
    getSearchFetching,
    getSearch,
    getHasMore,
    getToContext,
    getFromContext
} from './reducer';
import ProductsList from '../../components/ProductsList';

import {useParams} from 'react-router';

import "./style.css"
import InfiniteView from "../../components/InfiniteView";
import {setWindowScrollPosition} from "../../components/WindowDimensions";
import {getProducts, getProductsFilter} from "../Products/reducer";
import {fetchCatalogue} from "../Categories/actions";
import {
    fetchProducts,
    setCategoryFilter,
    setDisplayOrderFilter,
    setSellerFilter,
    setTagFilter
} from "../Products/actions";
import {getCategories} from "../Categories/reducer";
import FilterDropdown from "../../components/Filtration/menu";
import FilterDropdownMenu from "../../components/Filtration/dropdownMenu";
import FilterModal from "../../components/Filtration/filter";


export function isSearchView(pathname) {
    return pathname.indexOf('search') !== -1;
}
export default function Search(props) {

  const {routeAction, location, dispatch, token, userLocation, userData, mixpanel, isPageReloaded} = props;

  const {search} = useParams();

  const loading = useSelector((state) => getSearchFetching(state.search));
  const searchItems = useSelector((state) => getSearch(state.search));
  const hasMore = useSelector((state) => getHasMore(state.search));
  const fromContext = useSelector((state) => getFromContext(state.search));
  const searchContext = useSelector((state) => getToContext(state.search));
  const filter = useSelector((state) => getProductsFilter(state.products));

    useEffect(() => {
        if (filter && routeAction === 'PUSH') {
            dispatch(setTagFilter([]))
            dispatch(setCategoryFilter([]))
            dispatch(setDisplayOrderFilter([]))
            dispatch(setSellerFilter([]))
        }
    }, []);


  const filteredProducts = searchItems.filter(product =>
        product.categories.some(category => category.name === searchContext) && product !== false);


  const context = SEARCH_CONTEXTS.find(obj => obj.value === fromContext);
  const contextTitle = _.isObject(context) ? context.title : '';
  const title = () => {
      return(
          <>
              <div className='search-result-text'>Результаты поиска</div>
              <FilterDropdownMenu isCompany={true}/>
          </>
      )
  };
  const nothingTitle = () => {
      return(
          <>
            <div className='search-result-text'>По запросу "{search}" {contextTitle} ничего не найдено</div>
            {/*<FilterDropdownMenu/>*/}
          </>
      )
  }

  useEffect(() => {
      if ( routeAction === 'PUSH' || isPageReloaded ) {
              window.scrollTo(0, 0);
              dispatch(receiveSearch(searchContext, 1, []));
              readSearch(1, searchContext);
          }
          if (mixpanel)
              mixpanel.track('Search List View',
                  {...userLocation, ...userData, search, searchContext, fromContext, routeAction, ...location});
  }, [search, searchContext]);

  useEffect(() => {
        if (isPageReloaded) {
            setWindowScrollPosition();
        }
  }, [props]);

  const loadMore = () => readSearch(Math.round(searchItems.length / SEARCH_PAGE_COUNT) + 1, searchContext);

  const readSearch = (page, context)  => {
    dispatch(fetchSearch(
        token,
        context,
        {
              search,
              page,
              per_page: SEARCH_PAGE_COUNT,
              order: 'asc',
              orderby: 'id',
        }
    ));
  }

  if (!navigator.onLine)
      return (<Container><p>Нет подключения к Интернету</p></Container>);

  const loaderText = `Ищем '${search}' ${contextTitle}...`;

  if (loading && (searchItems.length === 0 || fromContext !== searchContext)) {
      return (
          <Dimmer active={true} inverted className="page-loader">
              <Loader active>{loaderText}</Loader>
          </Dimmer>
      );
  }


  return (
      <>
      <div className={"search-page"}>
      <InfiniteView
          dataLength={searchItems.length}
          nextFetch={loadMore}
          hasMore={hasMore}
          dataList={(
              <>
                  { fromContext === searchContext ?
                      <ProductsList products={searchContext === 'products' ? searchItems : filteredProducts}
                                        title={filteredProducts.length !== 0 || searchContext === 'products' ? title() : nothingTitle()} {...props}/> : '' }
              </>
          )}
          mustAuth={false}
          isAuth={!!token}
          isLoading={false}
          loaderText={loaderText}
          zeroText={<div className='search-result-text'>По запросу "{search}" {contextTitle} ничего не найдено</div>}
      />
      </div>
      </>
  );

}



