import {BrowserView, MobileView} from "react-device-detect";
import React, {useEffect, useState} from "react";
import './style.css'

import {Button, Dimmer, Divider, Dropdown, Image, Input, Loader, Modal, Rating} from "semantic-ui-react";
import ImageGallery from 'react-image-gallery';
import { ReactComponent as LocationIcon } from "../../icons/location-icon.svg";
import { ReactComponent as UserIcon } from "../../icons/user-icon.svg";
import { ReactComponent as CloseIcon} from "../../icons/close-icon.svg";
import {toastr} from "react-redux-toastr";
import {useSelector} from "react-redux";
import {getProducts, getProductsFetching} from "../Products/reducer";
import {useParams} from "react-router";
import {fetchProducts, PRODUCTS_PAGE_COUNT} from "../Products/actions";
import {getCompanies} from "../CongressOnline/reducer";
import Reviews from "../../components/Reviews";
import {fetchReviews} from "../../components/Reviews/actions";
import {addProduct} from "../Cart/actions";
import {getReviews} from "../../components/Reviews/reducer";
import LanguageModal from "../../components/LanguageModal/LanguageModal";
import {switchFavoriteCard} from "../../views/Favorite/actions";
import {getFavoriteCards} from "../../views/Favorite/reducer";

import {getCountries, isCountriesFetching} from "../../components/AddressCard/reducer";
import _ from "lodash";
import {putTicket} from "../../components/UserLogin/actions";
import PhoneCountryMask from "../../components/AddressCard/PhoneCountryMask";
import {ADDRESS_PHONE, ADDRESS_PHONE_COUNTRY} from "../../components/AddressCard/actions";




export default function ProductCard(props) {
    const {dispatch, token, navigate, userData, rating_count} = props;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [activeButton, setActiveButton] = useState(null);
    const [content, setContent] = useState(null);
    const [viewAll, setViewAll] = useState(false);
    const [ openReviewPopup, setOpenReviewPopup ] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pluses, setPluses] = useState('');
    const [minuses, setMinuses] = useState('');
    const [reviewText, setReviewText] = useState('');
    const countries = useSelector((state) => getCountries(state.address));
    const isFetchingCountries = useSelector((state) => isCountriesFetching(state.address));
    const favoriteCards = useSelector((state) => getFavoriteCards(state.favorites));
    const [mail, setMail] = useState("");
    const [mailDirty, setMailDirty] = useState(false);
    const [mailError, setMailError] = useState("Поле ввода не может быть пустым");

    const [open, setOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [name, setName] = useState("");
    const [formValid, setFormValid] = useState(false);
    const [messageValue, setMessageValue] = useState('');


    const products = useSelector((state) => getProducts(state.products));
    const isFetching = useSelector((state) => getProductsFetching(state.products));


    const { productId } = useParams();
    let productToDisplay = products ? products.find((product) => String(product.id) === productId) : null;


    useEffect(() => {
        const params = {
            page: 1,
            per_page: PRODUCTS_PAGE_COUNT,
            order: "asc",
            orderby: "id",
            id: productId,
            user_id: userData.id,
        };
        dispatch(fetchProducts(params));
    }, [productId]);

    const handleButtonClick = (index, handleChangePage) => {
        setActiveButton(index);
        handleChangePage;
    }

    const handleChangePluses = (e) => {
        setPluses(e.target.value);
    }

    const handleChangeMinuses = (e) => {
        setMinuses(e.target.value);
    }

    const handleChangeReviewText = (e) => {
        setReviewText(e.target.value);
    }

    const handleClickLeaveReview = () => {
        if (reviewText && pluses && minuses && token) {
            const newReview = {
                pluses: pluses,
                minuses: minuses,
                review: reviewText,
                first_name: userData.billing && userData.billing.first_name,
                last_name: userData.billing && userData.billing.last_name,
                date: new Date().toLocaleDateString()
            };
            setReviews([newReview, ...reviews]);
            setOpenReviewPopup(false)
            setMinuses('')
            setPluses('')
            setReviewText('')
        }
        if(token)
            if (!reviewText || !pluses || !minuses) {
                toastr.warning('Пожалуйста, заполните все поля')
            }
        if (!token) {
            toastr.warning('Чтобы оставить отзыв, пожалуйста, авторизуйтесь')
        }

    }

    let product = products ? products.find((product) => String(product.id) === productId) : null;


    let listPhoneCountries = [];
    if (!isFetchingCountries && countries && countries.countries) {
        listPhoneCountries = Object.values(countries.countries).map((element, index) => (
            {
                key:  index,
                text: element.country_ru,
                flag: _.lowerCase(element.iso),
                value: element.iso,
                className: "dropdown-item",
                phone_mask: element.phone_mask,
            }
        ));
    }

    const handleUserNameChange = (e) => {
        setUserName(e.target.value);
    }


    useEffect(() => {
        if (mailError) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    }, [mailError]);

    const mailHandler = (e) => {
        setMail(e.target.value);
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setMailError("Некорректный email адрес")
        } else {
            setMailError("")
        }
    }

    const nameHandler = (e) => {
        setName(e.target.value);
    }

    const textHandler = (e) => {
        setMessageValue(e.target.value);
    }

    const blurHandler = (e) => {
        switch (e.target.name) {
            case 'mail':
                setMailDirty(true);
                break;
        }
    }

    const handleChangeField = (fieldName, value) => {
        setPhoneNumber(value);
    }

    const createEmailTicket = (to, subject, message, email, phone, who) => {
        const ticket = {
            subject,
            message,
            email,
            phone,
            who,
            to,
        };
        dispatch(putTicket(token, ticket))
        // .then(() => {
        //     setIsLoading(false);
        // })
        // .catch(() => {
        //     setIsLoading(false);
        // });

    }

    const handleSubmitQuestion = () => {
        if (messageValue && phoneNumber && userName && mail) {
            createEmailTicket(
                'egor-ka.kravchenko@yandex.ru',
                'Коммерческое предложение на товар ' + ' ' + product.name + ' ' + ' через VENUE',
                product.name + ', ' + messageValue,
                mail,
                phoneNumber,
                userName
            )
            toastr.success(`Запрос успешно отправлен!`)
            setOpen(false)
            setMessageValue('')
            setMail('')
            setPhoneNumber('')
        }

        if (!phoneNumber || !userName || !mail || !messageValue) {
            toastr.warning(`Пожалуйста, заполните все поля`)
        }

    }

    const handleClose = () => {
        setOpen(false)
        setMailError('')
        setMailDirty(false)
        setUserName('')
        setMail('')
        setMessageValue('')
    }

    useEffect(() => {
        handleChangePageAboutProduct();
        setActiveButton(1)
    }, [])


    const handleChangePageAboutProduct = () => {
        setContent (
            <>
            <BrowserView>
                    <p className='text-about-product'
                       dangerouslySetInnerHTML={{__html: productToDisplay ? productToDisplay.description : ''}}>

                    </p>

                    <div className='reviews-container-bottom'>
                        <span className='reviews-bottom'>Отзывы</span>
                        <span className='count-reviews-bottom'>{productToDisplay ? productToDisplay.rating_count : ''}</span>

                    </div>
                    <Rating defaultRating={productToDisplay ? productToDisplay.average_rating : ''} disabled maxRating={5} size="huge"
                            style={{paddingLeft: '25px', paddingTop: '6px'}}/>

                    <div className='reviews-desktop'>
                        <Reviews {...props}/>
                        <div className='bottom-btn-container'>
                            <Button className='button-view-reviews'
                                    onClick={() => setOpenReviewPopup(true)}>Оставить отзыв</Button>

                        </div>
                    </div>
                </BrowserView>

                <MobileView>
                    <p className='text-about-product-mobile'
                       dangerouslySetInnerHTML={{__html: productToDisplay ? productToDisplay.description : ''}}>

                    </p>
                    <div className='reviews-container-bottom-mobile'>
                        <span className='reviews-bottom'>Отзывы</span>
                        <span className='count-reviews-bottom'>{productToDisplay ? productToDisplay.rating_count : ''}</span>

                    </div>
                    <Rating defaultRating={productToDisplay ? productToDisplay.average_rating : ''} disabled maxRating={5} size="large"
                            style={{paddingLeft: '15px', paddingTop: '6px'}}/>

                    <div className='reviews-mobile'>
                        <Reviews {...props}/>
                        <div className='bottom-btn-container-mobile'>
                            <div className='bottom-btn-reviews-mobile'>
                                <Button className='button-view-reviews'
                                        onClick={() => setOpenReviewPopup(true)}>Оставить отзыв</Button>
                            </div>
                        </div>
                    </div>
                </MobileView>

            </>
        )
    }

    const handleChangePagePromo = () => {
        setContent(
            <>

            </>
        )
    }

    function CardProduct(props) {
        const {
            product,
            navigate,
            token
        } = props;

        const categories = product.categories ? product.categories.map(elem => elem.name).join(", ") : '';
        const isFavorite = favoriteCards.some(favorite => favorite.id === product.id)

        const images = product.images ? product.images.map(elem => ({
            original: elem.src,
            thumbnail: elem.src
        })) : [];


        const handleClickBookmark = () => {
            dispatch(switchFavoriteCard({
                categories: product.categories,
                id: product.id,
                src: product.images[0].src,
                name: product.name,
                seller_company: product.seller_company.name,
                seller_company_id: product.seller_company.id
            }))

            if (isFavorite) {
                toastr.warning('Товар' + ' ' + product.name + ' ' + 'удален из избранного')
            } else {
                toastr.success('Товар' + ' ' + product.name + ' ' + 'добавлен в избранное')

            }
        }

        return (
            <>
                <BrowserView>
                    <div className='container-header-card'>
                        <div className='container-photo-card'>
                            <ImageGallery
                                items={images}
                                showNav={true}
                                showPlayButton={false}
                                fullScreen={true}
                                additionalClass="image-gallery-photo-card"
                                showFullScreenButton={true}
                            />
                        </div>

                        <div className='container-desc-card'>
                            <span className='desc-card title'>{product.name}</span>
                            <div className='rating-card-desc'>
                                <Rating defaultRating={product.average_rating} disabled maxRating={5} size="huge"/>
                                <span className='count-reviews-desc'>{product.rating_count}</span>
                            </div>
                            <span className='desc-card-text'
                                  dangerouslySetInnerHTML={{__html: product.short_description}}>
                            </span>
                            <span className='select-package-text'>Поставщик</span>
                            <div className='supplier-container-desc'
                                 onClick={() => navigate(`/congress-online/company/${product.seller_company.id}`)}>
                                <Image src={product.seller_company.logo} className='logo-supplier'/>
                                <span className='supplier-name'>{product.seller_company.name}</span>
                            </div>
                            <div className='categories-container'>
                                <span className='categories-text-desc'>Категории</span>
                                <div className='category-list'>
                                    {product.categories && product.categories.map((category, index) => (
                                        <span
                                            key={index}
                                            className='category-name'
                                            onClick={() => navigate(`/products/${category.id}`)}
                                            style={{paddingRight: '4px'}}
                                        >
                                            {category.name}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='container-payment-card'>
                            <div className='container-payment-one' >
                                <div className='container-buttons-payment'>
                                    <Button className='button-payment-mobile' onClick={() => setOpen(true)}>Запросить</Button>
                                    <Button className='button-payment' onClick={() => handleClickBookmark()}>
                                        {isFavorite ?
                                            'Удалить из избранного'
                                            :
                                            'Добавить в избранное'
                                        }
                                    </Button>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className='venue-product-btns-box'>
                        <Button id='venue-product-card-button' className={activeButton === 1 ? 'active' : ''}
                                onClick={() => handleButtonClick(1, handleChangePageAboutProduct())}>
                            О товаре
                        </Button>
                        <Button id='venue-product-card-button' className={activeButton === 2 ? 'active' : ''}
                                onClick={() => handleButtonClick(2, handleChangePagePromo())}>
                            Инструкции и документы
                        </Button>

                    </div>

                    {isFetching ? 'ЗАГРУЖОЕМ ИНФУ' : <div>{content}</div>}





                </BrowserView>

                <MobileView>
                    <div className='container-header-card-mobile'>
                        <div className='container-photo-card-mobile'>
                            <ImageGallery
                                items={images}
                                showNav={true}
                                showPlayButton={false}
                                fullScreen={true}
                                additionalClass="image-gallery-photo-card-mobile"
                                showFullscreenButton={true}
                            />
                        </div>

                        <div className='container-desc-card'>
                            <span className='desc-card title-mobile'>{product.name}</span>
                            <div className='rating-card-desc'>
                                <Rating defaultRating={product.average_rating} disabled maxRating={5} size="large"/>
                                <span className='count-reviews-desc-mobile'>{product.rating_count}</span>
                            </div>
                            <span className='desc-card-text-mobile'
                                  dangerouslySetInnerHTML={{__html: product.short_description}}>
                            </span>
                            <div className='container-payment-card-mobile'>
                                <div className='container-payment-one-mobile'>
                                    <div className='container-buttons-payment'>
                                        <Button className='button-payment-mobile' onClick={() => setOpen(true)}>Запросить</Button>
                                        <Button className='button-payment-mobile' onClick={() => handleClickBookmark()}>В
                                            избранное</Button>
                                    </div>
                                </div>
                            </div>
                            <span className='select-package-text-mobile'>Поставщик</span>
                            <div className='supplier-container-desc'
                                 onClick={() => navigate(`/congress-online/company/${product.seller_company.id}`)}>
                                <Image src={product.seller_company.logo} className='logo-supplier-mobile'/>
                                <span className='supplier-name-mobile'>{product.seller_company.name}</span>
                            </div>
                            <div className='categories-container'>
                                <span className='categories-text-desc-mobile'>Категории</span>
                                <div className='category-list'>
                                    {product.categories && product.categories.map((category, index) => (
                                        <span
                                            key={index}
                                            className='category-name'
                                            onClick={() => navigate(`/products/${category.id}`)}
                                            style={{paddingRight: '4px'}}
                                        >
                                            {category.name}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>



                    </div>
                    <div className='venue-product-btns-box-mobile'>
                        <Button id='venue-product-card-button-mobile' className={activeButton === 1 ? 'active' : ''}
                                onClick={() => handleButtonClick(1, handleChangePageAboutProduct())}>
                            О товаре
                        </Button>
                        <Button id='venue-product-card-button-mobile' className={activeButton === 2 ? 'active' : ''}
                                onClick={() => handleButtonClick(2, handleChangePagePromo())}>
                            Инструкции и документы
                        </Button>

                    </div>
                    <div>{content}</div>

                </MobileView>
            </>
        )
    }

    return (
        <>
            <BrowserView>
                <div className='container-page-product'>
                    <span className='card-product title'>Товар</span>
                    <div>
                        <Dimmer className="page-loader" active={isFetching} inverted>
                            <Loader active>Загружаем информацию ...</Loader>
                        </Dimmer>

                        {productToDisplay ? (
                            <CardProduct
                                key={productToDisplay.id}
                                product={productToDisplay}
                                showLoader
                                {...props}
                            />
                        ) : "Товара с таким номером не существует"}

                    </div>


                    <Modal
                        className='venue-modal'
                        centered={false}
                        open={openReviewPopup}
                        onClose={() => setOpenReviewPopup(false)}
                    >
                        <Modal.Content className="venue-modal-content">
                            <div className="venue-modal-text venue-title-text">Оставьте отзыв</div>
                            <div className='venue-modal-text venue-mini-text'>*Отзыв должен касаться данного товара.
                            </div>
                            <CloseIcon className="venue-modal-close-icon" onClick={() => setOpenReviewPopup(false)}/>

                            <Rating defaultRating={0} clearable maxRating={5} size="huge"/>
                            <Input
                                className='venue-modal-placeholder'
                                type='text'
                                name='name'
                                placeholder='Плюсы*'
                                transparent
                                value={pluses}
                                onChange={handleChangePluses}
                            />

                            <Input
                                className='venue-modal-placeholder'
                                type='text'
                                name='review'
                                placeholder='Минусы*'
                                transparent
                                value={minuses}
                                onChange={handleChangeMinuses}
                            />

                            <Input
                                className='venue-modal-placeholder'
                                type='text'
                                name='review'
                                placeholder='Отзыв*'
                                transparent
                                value={reviewText}
                                onChange={handleChangeReviewText}
                            />

                            <div className='bottom-btn-reviews'>
                                <Button className='button-view-reviews'
                                        onClick={() => handleClickLeaveReview()}>Отправить</Button>
                            </div>

                        </Modal.Content>
                    </Modal>

                    <Modal
                        open={open}
                        onClose={() => handleClose()}
                        size='tiny'
                        className='modal-request'
                        centered={false}
                    >

                        <CloseIcon className="modal-request-close-icon" onClick={() => setOpen(false)} />
                        <Modal.Content className="modal-request-content">
                            <div className="modal-request-title">Получите коммерческое предложение по данному товару</div>
                            <Input
                                className='modal-request-placeholder'
                                type='text'
                                name='name'
                                placeholder='ФИО'
                                transparent
                                onChange={e => {nameHandler(e); handleUserNameChange(e)}}
                                onBlur={e => blurHandler(e)}
                                value={userName}
                            />

                            <PhoneCountryMask
                                extraClass="edit-input-phone edit-input"
                                name="phone"
                                countries={listPhoneCountries}
                                country="RU"
                                loading={isFetching}
                                phone={phoneNumber}
                                handleChangeField={handleChangeField}
                                nameCountry={ADDRESS_PHONE_COUNTRY}
                                namePhone={ADDRESS_PHONE}
                                fluid={true}
                            />
                            <Input
                                className='modal-request-placeholder'
                                type='text'
                                name='mail'
                                placeholder='Электронная почта'
                                transparent
                                value={mail}
                                onChange={e => mailHandler(e)}
                                onBlur={e => blurHandler(e)}
                            />
                            {(mailDirty && mailError) && <div style={{color: 'red'}}>{mailError}</div>}
                            <Input
                                className='modal-request-placeholder'
                                type='text'
                                name='question'
                                placeholder='Примерный вес продукции (40кг, 700кг, 1т)'
                                transparent
                                value={messageValue}
                                onChange={e => textHandler(e)}
                                onBlur={e => blurHandler(e)}
                            />

                        </Modal.Content>
                        <Button className='modal-request-btn' onClick={handleSubmitQuestion}>
                            Запросить
                        </Button>
                    </Modal>
                </div>
            </BrowserView>

            <MobileView>
                <div className='container-page-product'>
                    <div>
                        <Dimmer className="page-loader" active={isFetching} inverted>
                            <Loader active>Загружаем информацию ...</Loader>
                        </Dimmer>

                        {productToDisplay ? (
                            <CardProduct
                                key={productToDisplay.id}
                                product={productToDisplay}
                                showLoader
                                {...props}
                            />
                        ) : "Товара с таким номером не существует"}

                    </div>
                    <Modal
                        className='venue-modal'
                        centered={false}
                        open={openReviewPopup}
                        onClose={() => setOpenReviewPopup(false)}
                    >
                        <Modal.Content className="venue-modal-content">
                            <div className="venue-modal-text venue-title-text">Оставьте отзыв</div>
                            <div className='venue-modal-text venue-mini-text'>*Отзыв должен касаться данного товара.
                            </div>
                            <CloseIcon className="venue-modal-close-icon" onClick={() => setOpenReviewPopup(false)}/>

                            <Rating defaultRating={0} clearable maxRating={5} size="huge"/>
                            <Input
                                className='venue-modal-placeholder'
                                type='text'
                                name='name'
                                placeholder='Плюсы*'
                                transparent
                                value={pluses}
                                onChange={handleChangePluses}

                            />

                            <Input
                                className='venue-modal-placeholder'
                                type='text'
                                name='review'
                                placeholder='Минусы*'
                                transparent
                                value={minuses}
                                onChange={handleChangeMinuses}
                            />

                            <Input
                                className='venue-modal-placeholder'
                                type='text'
                                name='review'
                                placeholder='Отзыв*'
                                transparent
                                value={reviewText}
                                onChange={handleChangeReviewText}
                            />

                            <div className='bottom-btn-reviews'>
                                <Button className='button-view-reviews'
                                        onClick={() => handleClickLeaveReview()}>Отправить</Button>
                            </div>

                        </Modal.Content>
                    </Modal>

                    <Modal
                        open={open}
                        onClose={() => handleClose()}
                        size='tiny'
                        className='modal-request'
                        centered={false}
                    >

                        <CloseIcon className="modal-request-close-icon" onClick={() => setOpen(false)} />
                        <Modal.Content className="modal-request-content">
                            <div className="modal-request-title">Получите коммерческое предложение по данному товару</div>
                            <Input
                                className='modal-request-placeholder'
                                type='text'
                                name='name'
                                placeholder='ФИО'
                                transparent
                                onChange={e => {nameHandler(e); handleUserNameChange(e)}}
                                onBlur={e => blurHandler(e)}
                                value={userName}
                            />

                            <PhoneCountryMask
                                extraClass="edit-input-phone edit-input"
                                name="phone"
                                countries={listPhoneCountries}
                                country="RU"
                                loading={isFetching}
                                phone={phoneNumber}
                                handleChangeField={handleChangeField}
                                nameCountry={ADDRESS_PHONE_COUNTRY}
                                namePhone={ADDRESS_PHONE}
                                fluid={true}
                            />
                            <Input
                                className='modal-request-placeholder'
                                type='text'
                                name='mail'
                                placeholder='Электронная почта'
                                transparent
                                value={mail}
                                onChange={e => mailHandler(e)}
                                onBlur={e => blurHandler(e)}
                            />
                            {(mailDirty && mailError) && <div style={{color: 'red'}}>{mailError}</div>}
                            <Input
                                className='modal-request-placeholder'
                                type='text'
                                name='question'
                                placeholder='Примерный вес продукции (40кг, 700кг, 1т)'
                                transparent
                                value={messageValue}
                                onChange={e => textHandler(e)}
                                onBlur={e => blurHandler(e)}
                            />

                        </Modal.Content>
                        <Button className='modal-request-btn' onClick={handleSubmitQuestion}>
                            Запросить
                        </Button>
                    </Modal>
                </div>
            </MobileView>
        </>
    )
}
